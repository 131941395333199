import React from 'react';
import PropTypes from 'prop-types';
import PickQuestion from "components/Questions/PickQuestion";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/styles/components/questionStyle";

const useStyles = makeStyles(styles);

const MultipleChooseQuestion = ({ title, numbers, options, value = {}, onSelectNum, onSelectType }) => {
	const classes = useStyles();

	return (
		<React.Fragment>
			<h4 className={ classes.cardTitle }>{ title }</h4>
			<div className={ classes.options }>
				<div>
					{ numbers.map( num => {
						return (
							<Button 
								key={ num } 
								round
								color={value.num === num ? "primary" : "transparent"}
								className={classes.roundButton}
								onClick={e => onSelectNum(num)}
							>
								{ num }
							</Button>
						)
					})}		
				</div>
				<div>
					<PickQuestion
						label="Tipo de carpinteria"
				    	helperText="Por favor, elige el tipo de carpinteria"
				    	value={value.type || ""}
				    	onItemSelected={(item) => onSelectType(item)}
						options={options}
						multiple
					/>		
				</div>				
			</div>
		</React.Fragment>
	)
}

MultipleChooseQuestion.propTypes = {
  title: PropTypes.string.isRequired,
  numbers: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.number,
  onSelectNum: PropTypes.func.isRequired,
  onSelectType: PropTypes.func.isRequired
}

export default MultipleChooseQuestion;
