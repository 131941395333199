import React from 'react'
import ChooseQuestion from "components/Questions/ChooseQuestion";
import MultipleChooseQuestion from "components/Questions/MultipleChooseQuestion";
import GamaQuestion from "components/Questions/GamaQuestion";
import PickQuestion from "components/Questions/PickQuestion";
import TimeQuestion from "components/Questions/TimeQuestion";
import CommentQuestion from "components/Questions/CommentQuestion";
import ThanksMessage from "components/Questions/ThanksMessage";
import Button from "components/CustomButtons/Button.js";
import AddIcon from '@material-ui/icons/Add';

const questions = ({
	state, 
	dispatch, 
	actions, 
	increaseTab, 
	goToTab, 
	handleExit, 
	tabActive, 
}) => {

	const superficie = {
		tabContent: (
			<PickQuestion
				title="¿Cuántos metros cuadrados tiene la casa?"
				label="Superficie - m2"
		    	helperText="Por favor, elige la superficie aproximada de la casa"
		    	value={state.superficie}
		    	onItemSelected={(item) => {
		    		dispatch({ type: actions.SET_SUPERFICIE, payload: item });
		    		setTimeout(() => {
		    			increaseTab();
		    		}, 700)		    		
		    	}}
				options={[
				  { value: 30, label: '30 m2' },
				  { value: 40, label: '40 m2' },
				  { value: 50, label: '50 m2' },
				  { value: 60, label: '60 m2' },
				  { value: 70, label: '70 m2' },
				  { value: 80, label: '80 m2' },
				  { value: 90, label: '90 m2' },
				  { value: 100, label: '100 m2' },
				  { value: 110, label: '110 m2' },
				  { value: 120, label: '120 m2' },
				  { value: 130, label: '130 m2' },
				  { value: 140, label: '140 m2' },
				  { value: 150, label: '150 m2' },
				  { value: 160, label: '160 m2' },
				  { value: 170, label: '170 m2' },
				  { value: 180, label: '180 m2' },
				  { value: 190, label: '190 m2' },
				  { value: 200, label: '200 m2' },
				  { value: 210, label: '210 m2' },
				  { value: 220, label: '220 m2' },
				]}
			/>
		)
	};

	const banos = {
		tabContent: (
			<div>
				<ChooseQuestion
					title="¿Quieres reformar el/los baños?"
					options={[{text: "Sí", val: true}, {text: "No", val: false}].map(tr => (
						{
							text: tr.text,
							color: tr.val === state.banosReforma ? "primary" : "default",
							onClick: () => {
								dispatch({ type: actions.SET_BANOS_REFORMA, payload: tr.val });
								if (!tr.val) increaseTab(500);
							}
						}
					))}
				/>
				{state.banosReforma && tabActive === 1 && (
					<PickQuestion
						title="¿Cuántos baños quieres reformar?"
						label="Número de baños"
				    	helperText="Por favor, elige el número de baños a reformar"
				    	value={state.banosNum}
				    	onItemSelected={(item) => {
				    		dispatch({ type: actions.SET_BANOS_NUM, payload: item });
				    		increaseTab(700);		    		
				    	}}
						options={[
						  { value: 1, label: '1' },
						  { value: 2, label: '2' },
						  { value: 3, label: '3' },
						  { value: 4, label: '4' },
						  { value: 5, label: '5' },
						  { value: 6, label: '6' },
						]}
					/>
				)}
			</div>
		)
	};

	const cocina = {
		tabContent: (
			<div>
				<ChooseQuestion
					title="¿Quieres reformar la cocina?"
					options={[{text: "Sí", val: true}, {text: "No", val: false}].map(tr => (
						{
							text: tr.text,
							color: tr.val === state.cocinaReforma ? "primary" : "default",
							onClick: () => {
								dispatch({ type: actions.SET_COCINA_REFORMA, payload: tr.val });
								increaseTab(500);
							}
						}
					))}
				/>
			</div>
		)
	};

	const fontaneria = {
		tabContent: (
			<ChooseQuestion
				title="¿Necesitas cambiar la fontanería de la casa?"
				options={[{text: "Sí", val: true}, {text: "No", val: false}].map(tr => (
					{
						text: tr.text,
						color: tr.val === state.fontaneria ? "primary" : "default",
						onClick: () => {
							dispatch({ type: actions.SET_FONTANERIA, payload: tr.val });
							increaseTab(500);
						}
					}
				))}
			/>
		)
	};

	const electricidad = {
		tabContent: (
			<ChooseQuestion
				title="¿Necesitas cambiar la instalación eléctrica de la casa?"
				options={[{text: "Sí", val: true}, {text: "No", val: false}].map(tr => (
					{
						text: tr.text,
						color: tr.val === state.electricidad ? "primary" : "default",
						onClick: () => {
							dispatch({ type: actions.SET_ELECTRICIDAD, payload: tr.val });
							increaseTab(500);
						}
					}
				))}
			/>
		)
	};

	const alisado = {
		tabContent: (
			<ChooseQuestion
				title="¿Quieres alisar las paredes?"
				options={[{text: "Sí", val: true}, {text: "No", val: false}].map(tr => (
					{
						text: tr.text,
						color: tr.val === state.alisado ? "primary" : "default",
						onClick: () => {
							dispatch({ type: actions.SET_ALISADO, payload: tr.val });
							increaseTab(500);
						}
					}
				))}
			/>
		)
	};

	const pintura = {
		tabContent: (
			<ChooseQuestion
				title="¿Quieres pintar la casa?"
				options={[{text: "Sí", val: true}, {text: "No", val: false}].map(tr => (
					{
						text: tr.text,
						color: tr.val === state.pintura ? "primary" : "default",
						onClick: () => {
							dispatch({ type: actions.SET_PINTURA, payload: tr.val });
							increaseTab(500);
						}
					}
				))}
			/>
		)
	};

	const puertaEntrada = {
		tabContent: (
			<ChooseQuestion
				title="¿Quieres cambiar la puerta de entrada?"
				options={[{text: "Sí", val: true}, {text: "No", val: false}].map(tr => (
					{
						text: tr.text,
						color: tr.val === state.puertaEntrada ? "primary" : "default",
						onClick: () => {
							dispatch({ type: actions.SET_PUERTA_ENTRADA, payload: tr.val });
							increaseTab(500);
						}
					}
				))}
			/>
		)
	};

	const suelo = {
		tabContent: (
			<div>
				<ChooseQuestion
					title="¿Quieres cambiar el suelo?"
					options={[{text: "Sí", val: true}, {text: "No", val: false}].map(tr => (
						{
							text: tr.text,
							color: tr.val === state.sueloReforma ? "primary" : "default",
							onClick: () => {
								dispatch({ type: actions.SET_SUELO_REFORMA, payload: tr.val });
								if (!tr.val) increaseTab(500);	 
							}
						}
					))}
				/>
				{state.sueloReforma && tabActive === 8 || true && (
					<div>
						<PickQuestion
							title="¿Cuántos metros de suelo?"
							label="Metros de suelo"
					    	helperText="Por favor, elige los METROS de suelo a reformar"
					    	value={state.sueloMetros}
					    	onItemSelected={(item) => {
					    		dispatch({ type: actions.SET_SUELO_METROS, payload: item });
					    		if (state.sueloTipo) increaseTab(500);	 
					    	}}
							options={[
							  { value: 20, label: '20 m2' },
							  { value: 30, label: '30 m2' },
							  { value: 40, label: '40 m2' },
							  { value: 50, label: '50 m2' },
							  { value: 60, label: '60 m2' },
							  { value: 70, label: '70 m2' },
							  { value: 80, label: '80 m2' },
							  { value: 90, label: '90 m2' },
							  { value: 100, label: '100 m2' },
							  { value: 110, label: '110 m2' },
							  { value: 120, label: '120 m2' },
							  { value: 130, label: '130 m2' },
							  { value: 140, label: '140 m2' },
							  { value: 150, label: '150 m2' },
							  { value: 160, label: '160 m2' },
							  { value: 170, label: '170 m2' },
							  { value: 180, label: '180 m2' },
							  { value: 190, label: '190 m2' },
							  { value: 200, label: '200 m2' },
							  { value: 210, label: '210 m2' },
							  { value: 220, label: '220 m2' },
							]}
						/>

						<PickQuestion
							title="Tipo de suelo"
							label="Tipo de suelo"
					    	helperText="Por favor, elige el TIPO de suelo"
					    	value={state.sueloTipo}
					    	onItemSelected={(item) => {
					    		dispatch({ type: actions.SET_SUELO_TIPO, payload: item });
					    		if (state.sueloMetros) increaseTab(500);	 		
					    	}}
							options={[
							  { value: "ac4", label: '8mm AC4' },
							  { value: "ac5", label: '12mm AC5' },
							]}
						/>

						<GamaQuestion 
							mediaPrice={1000}
							mediaContent={
								<React.Fragment>
									<a href="/" target="_blank" rel="noreferrer noopener">Suelo 1</a>
									<a href="/" target="_blank" rel="noreferrer noopener">Suelo 2</a>
								</React.Fragment>
							}
							altaPrice={2000}
							altaContent={
								<React.Fragment>
									<a href="/" target="_blank" rel="noreferrer noopener">Suelo 1</a>
									<a href="/" target="_blank" rel="noreferrer noopener">Suelo 2</a>
								</React.Fragment>
							}
							exclusivePrice={3000}
							exclusiveContent={
								<React.Fragment>
									<a href="/" target="_blank" rel="noreferrer noopener">Suelo 1</a>
									<a href="/" target="_blank" rel="noreferrer noopener">Suelo 2</a>
								</React.Fragment>
							}
						/>
					</div>
				)}
			</div>
		)
	};

	const ventanas = {
		tabContent: (
			<div>
				<MultipleChooseQuestion
					title="¿Quieres cambiar ventanas? ¿Cuántas?"
					numbers={[1, 2, 3, 4, 5, 6, 7, 8]}
					options={[
						{value: "v_aluminio", label: "Ventana Aluminio"},
						{value: "v_aluminio2", label: "Ventana Aluminio 2"},
						{value: "v_aluminio3", label: "Ventana Aluminio 3"}
					]}
					value={state.ventanas}
					onSelectNum={val => {
						const type = state.ventanas && state.ventanas.type;
			    		dispatch({ type: actions.SET_VENTANAS, payload: {num: val, type: type} });
			    	}}
			    	onSelectType={val => {
						const num = state.ventanas && state.ventanas.num;
			    		dispatch({ type: actions.SET_VENTANAS, payload: {num: num, type: val} });
			    	}}
				/>

				
				<Button 
					color="primary"
					fullWidth
					onClick={e => increaseTab()}
				>
					Seguir
				</Button>
			</div>
		)
	};

	const puertas = {
		tabContent: (
			<div>
				<MultipleChooseQuestion
					title="¿Quieres cambiar puertas? ¿Cuántas?"
					numbers={[1, 2, 3, 4, 5, 6, 7, 8]}
					options={[
						{value: "v_aluminio", label: "Puerta Aluminio"},
						{value: "v_aluminio2", label: "Puerta Aluminio 2"},
						{value: "v_aluminio3", label: "Puerta Aluminio 3"}
					]}
					value={state.puertas || ""}
					onSelectNum={val => {
						const type = state.puertas && state.puertas.type;
			    		dispatch({ type: actions.SET_PUERTAS, payload: {num: val, type: type} });
			    	}}
			    	onSelectType={val => {
						const num = state.puertas && state.puertas.num;
			    		dispatch({ type: actions.SET_PUERTAS, payload: {num: num, type: val} });
			    	}}
				/>

				
				<Button 
					color="primary"
					fullWidth
					onClick={e => increaseTab()}
				>
					Terminar
				</Button>
			</div>
		)
	};

	const tabThanks = {
		tabContent: (
			<ThanksMessage
				title="El coste aproximado de la reforma de tu piso es de 5500€"
				subtitle="Precios orientativos"
				handleExit={() => handleExit()}	
				tabActive={tabActive}	
			/>
		)
	};

	// const tabTherapyTried = {
	// 	tabContent: (
	// 		<PickQuestion
	// 			title={`¿Qué terapia has probado con la enfermedad ${state.disease && state.disease.name}?`}
	// 			label="Terapias"
	// 	    	placeholder="Empieza a escribir una terapia"
	// 	    	hoverColor="primary"
	// 	    	onItemSelected={(item) => {
	// 	    		dispatch({ type: actions.SET_THERAPY, payload: item });
	// 	    		increaseTab();
	// 	    	}}
	// 	    	clearState={tabActive === 1}
	// 			options={therapies}
	// 		/>
	// 	)
	// };

	// const tabDuration = {
	// 	tabContent: (
	// 		<TimeQuestion
	// 			title="¿Durante cuánto tiempo has usado la terapia?"
	// 			numbers={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
	// 			periods={[
	// 				{payload: "week", label: "Semanas"}, 
	// 				{payload: "month", label: "Meses"}, 
	// 				{payload: "year", label: "Años"}
	// 			]}
	// 			number={state.durationNum}
	// 			period={state.durationPeriod}
	// 			onNumberSelected={num => {
	// 	    		dispatch({ type: actions.SET_DURATION_NUM, payload: num });
	// 	    		if (state.durationPeriod) increaseTab();
	// 	    	}}
	// 	    	onPeriodSelected={period => {
	// 	    		dispatch({ type: actions.SET_DURATION_PERIOD, payload: period.payload });
	// 	    		if (state.durationNum) increaseTab();
	// 	    	}}
	// 		/>
	// 	)
	// };

	// const tabFrequency = {
	// 	tabContent: (
	// 		<TimeQuestion
	// 			title="¿Con qué frecuencia?"
	// 			numbers={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
	// 			periods={[
	// 				{payload: "day", label: "Al día"}, 
	// 				{payload: "week", label: "A la semana"}, 
	// 				{payload: "month", label: "Al mes"}
	// 			]}
	// 			number={state.frequencyNum}
	// 			period={state.frequencyPeriod}
	// 			onNumberSelected={num => {
	// 	    		dispatch({ type: actions.SET_FREQUENCY_NUM, payload: num });
	// 	    		if (state.frequencyPeriod) increaseTab();
	// 	    	}}
	// 	    	onPeriodSelected={period => {
	// 	    		dispatch({ type: actions.SET_FREQUENCY_PERIOD, payload: period.payload });
	// 	    		if (state.frequencyNum) increaseTab();
	// 	    	}}
	// 		/>
	// 	)
	// };

	// const tabDidItWork = {
	// 	tabContent: (
	// 		<ChooseQuestion
	// 			title="¿Te ha funcionado?"
	// 			options={[{text: "uno", rate: 10}, {text: "dos", rate: 20}].map(tr => (
	// 				{
	// 					text: tr.text,
	// 					onClick: () => {
	// 						dispatch({ type: actions.SET_RATE, payload: tr.rate });
	// 						increaseTab();
	// 					}
	// 				}
	// 			))}
	// 		/>
	// 	)
	// };

	// const tabComment = {
	// 	tabContent: (
	// 		<CommentQuestion
	// 			title="Cuenta tu experiencia y ayuda a la comunidad..."
	// 			handleComment={(comment) => dispatch({ type: actions.SET_COMMENT, payload: comment })}
	// 			handleClick={() => {
	// 				saveFullQuestion();
	// 				increaseTab();
	// 			}}
	// 			comment={state.comment}
	// 		/>
	// 	)
	// };

	// const tabThanks = {
	// 	tabContent: (
	// 		<ThanksMessage
	// 			title={!state.everTried ? "Gracias" : "Gracias por tu aportación"}
	// 			subtitle={state.everTried ? "Has ganado 5 puntos" : undefined}
	// 			handleExit={() => handleExit()}	
	// 			tabActive={tabActive}	
	// 		/>
	// 	)
	// };

	let tabs = [suelo, superficie, banos, cocina, fontaneria, electricidad, alisado, pintura, puertaEntrada, suelo, ventanas, puertas, tabThanks];		

	return tabs;
}

export default questions;