import React from 'react';
import PropTypes from 'prop-types';

// import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import NavPills from "components/NavPills/NavPills.js";

import questions from "components/Budget/questions";

// import styles from "assets/jss/styles/components/budgetStyle";

// const useStyles = makeStyles(styles);

const initialState = {};

const actions = {
	SET_FONTANERIA: "SET_FONTANERIA",
  SET_SUPERFICIE: "SET_SUPERFICIE",
  SET_BANOS_REFORMA: "SET_BANOS_REFORMA",
  SET_BANOS_NUM: "SET_BANOS_NUM",
  SET_COCINA_REFORMA: "SET_COCINA_REFORMA",
	SET_ELECTRICIDAD: "SET_ELECTRICIDAD",
	SET_ALISADO: "SET_ALISADO",
	SET_PINTURA: "SET_PINTURA",
	SET_PUERTA_ENTRADA: "SET_PUERTA_ENTRADA",
	SET_SUELO_REFORMA: "SET_SUELO_REFORMA",
	SET_SUELO_METROS: "SET_SUELO_METROS",
	SET_SUELO_TIPO: "SET_SUELO_TIPO",
  SET_VENTANAS: "SET_VENTANAS",
  SET_PUERTAS: "SET_PUERTAS"
}

/*
const object = {
  superficie: number, //m2
  numBanos: number,
  fontaneria: bool,
}
*/

const reducer = (state, action) => {
  switch (action.type) {    
    case actions.SET_SUPERFICIE:
      return {
      	...state,
      	superficie: action.payload
      }
    case actions.SET_BANOS_REFORMA:
      return {
        ...state,
        banosReforma: action.payload
      }
    case actions.SET_BANOS_NUM:
      return {
        ...state,
        banosNum: action.payload
      }
    case actions.SET_COCINA_REFORMA:
      return {
        ...state,
        cocinaReforma: action.payload
      }
    case actions.SET_FONTANERIA:
      return {
        ...state,
        fontaneria: action.payload
      }
    case actions.SET_ELECTRICIDAD:
      return {
        ...state,
        electricidad: action.payload
      }
    case actions.SET_ALISADO:
      return {
      	...state,
      	alisado: action.payload
      }
    case actions.SET_PINTURA:
      return {
      	...state,
      	pintura: action.payload
      }
    case actions.SET_PUERTA_ENTRADA:
      return {
      	...state,
      	puertaEntrada: action.payload
      }
    case actions.SET_SUELO_REFORMA:
      return {
      	...state,
      	sueloReforma: action.payload
      }
    case actions.SET_SUELO_METROS:
      return {
      	...state,
      	sueloMetros: action.payload
      }
    case actions.SET_SUELO_TIPO:
      return {
      	...state,
      	sueloTipo: action.payload
      }
    case actions.SET_VENTANAS:
      return {
        ...state,
        ventanas: action.payload
      }
    case actions.SET_PUERTAS:
      return {
        ...state,
        puertas: action.payload
      }
    default:
      return state;
  }
};

const NewBudget = ({ handleExit, diseaseId, diseaseName, therapyId, therapyName, userId, pageType }) => {
	const [state, dispatch] = React.useReducer(reducer, initialState);
	const [tabActive, setTabActive] = React.useState(0);

  const card = {
    margin: 0,
    boxShadow: "none",
    overflow: "auto"
  };

	const increaseTab = (dur) => {
    setTimeout(() => {
      setTabActive(tabActive + 1);
    }, dur)		
	}

	const goToTab = num => {
		setTabActive(num);
	}


  const tabs = () => questions({
    state, 
    dispatch, 
    actions, 
    increaseTab, 
    goToTab, 
    handleExit, 
    tabActive
  });  

	return (
		<Card style={card}>
			<CardBody>
				<NavPills
					styles={{padding: 0}}
          alignCenter
          noHeader
          active={tabActive}
          setActive={setTabActive}
          tabs={tabs()}
        />
			</CardBody>
		</Card>
	)
}

NewBudget.propTypes = {
  handleExit: PropTypes.func.isRequired,
  diseaseId: PropTypes.string,
  therapyId: PropTypes.string,
  diseaseName: PropTypes.string,
  therapyName: PropTypes.string,
  userId: PropTypes.string,
  pageType: PropTypes.string
}

export default NewBudget