import React from 'react'
import classNames from 'classnames'
import Layout from 'layout/layout'
import Typist from 'react-typist'
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Parallax from 'components/Parallax/Parallax.js'
import BudgetPanel from 'components/Budget/BudgetPanel'
import landingPageStyle from 'assets/jss/styles/pages/landingPageStyle.js'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(landingPageStyle);

const texts = [
  "Reformas de baños y cocinas",
  "Reformas integrales de viviendas",
  "Contrucción de viviendas",
  "Arquitectura e ingeniería",
  "Instalaciones eléctricas",
  "Fontanería",
]

const TestPage = ({ data }) => {
  const [renderMsg, setRenderMsg] = React.useState(true);
  const classes = useStyles()
  const delay = 1500;

  const reactive = () => {
    setTimeout(() => {
      setRenderMsg(true);
    }, 1000)
  }

  const onHeaderTyped = () => {
    reactive();
    setRenderMsg(false);
  }

  return (
    <Layout
      pageTitle={data.site.siteMetadata.siteTitle}
      pageDescription={data.site.siteMetadata.siteDescription}
      headerImageGatsby={data.reformasJson.headerImage.childImageSharp.fluid}
      filter="dark"
      content={
        <React.Fragment>
          {renderMsg ? (
            <h2 className={classes.typist}>
              <Typist
                className="typist-header"
                avgTypingDelay={20}
                startDelay={1000}
                onTypingDone={onHeaderTyped}
              >
                <span>{texts[0]}</span>
                <Typist.Backspace count={texts[0].length} delay={delay} />
                <span>{texts[1]}</span>
                <Typist.Backspace count={texts[1].length} delay={delay} />
                <span>{texts[2]}</span>
                <Typist.Backspace count={texts[2].length} delay={delay} />
                <span>{texts[3]}</span>
                <Typist.Backspace count={texts[3].length} delay={delay} />
                <span>{texts[4]}</span>
                <Typist.Backspace count={texts[4].length} delay={delay} />
                <span>{texts[5]}</span>
                <Typist.Backspace count={texts[5].length} delay={delay} />
              </Typist>
            </h2>
          ) : (
            <h2 className={classes.typist}>|</h2>
          )}

          <h4 className={classes.subtitle}>A un precio sin compentencia</h4>

          <Link to="/presupuesto">
            <Button color={'primary'} className={classes.navButton}>
              Solicita Presupuesto
            </Button>
          </Link>

          <br />
        </React.Fragment>
      }
    >
      <BudgetPanel />
    </Layout>
  )  
}

export default TestPage

export const query = graphql`
  query TestpageQuery {
    site {
      id
      siteMetadata {
        siteTitle
        siteDescription
      }
    }
    reformasJson {
      headerImage {
        childImageSharp {
          fluid(maxHeight: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;