import { 
	cardTitle,
	whiteColor,
	primaryColor,
  grayColor,
  blackColor,
  infoColor,
  hexToRgb,
  card
} from "assets/jss/styles.js";

const questionStyle = {
  cardTitle: {
    ...cardTitle,
    textAlign: "center",
    backgroundColor: infoColor[0],
    color: `${whiteColor} !important`,
    // color: blackColor,
    // borderBottom: "1px solid black !important",
    marginTop: "2.5rem !important"
  },
  options: {
  	padding: "20px 0",
  	textAlign: "center",
  	// height: "220px"
  },
  card: {
    ...card,
    padding: "20px"
  },
  subSection: {
  	paddingBottom: "30px"
  },
  roundButton: {
  	padding: "5px 10px",
  	"&:hover": {
  		backgroundColor: primaryColor[0],
  		color: whiteColor
  	}
  },
  periodButton: {
  	padding: "9px 20px",
  	"&:hover": {
  		backgroundColor: primaryColor[0],
  		color: whiteColor
  	}
  },
  btnWrap: {
  	whiteSpace: "normal"
  },
  textField: {
    width: "100%"
  },

  //CheckBox
  checkboxAndRadio: {
    position: 'relative',
    display: 'block',
    marginTop: '10px',
    marginBottom: '10px',
  },
  checkboxAndRadioHorizontal: {
    position: 'relative',
    display: 'block',
    '&:first-child': {
      marginTop: '10px',
    },
    '&:not(:first-child)': {
      marginTop: '-14px',
    },
    '& .MuiFormControlLabel-root': {
      width: "100%"
    },
    marginTop: '0',
    marginBottom: '0',
  },
  radioChecked: {
    width: '16px',
    height: '16px',
    border: '1px solid ' + primaryColor[0],
    borderRadius: '50%',
  },
  radioUnchecked: {
    width: '0px',
    height: '0px',
    padding: '7px',
    border: '1px solid rgba(' + hexToRgb(blackColor) + ', .54)',
    borderRadius: '50%',
  },
  radio: {
    color: primaryColor[0] + '!important',
  },
  radioRoot: {
    padding: '16px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  label: {
    cursor: 'pointer',
    paddingLeft: '0',
    color: grayColor[12],
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    display: 'inline-flex',
    transition: '0.3s ease all',
    letterSpacing: 'unset',
    width: "100%"
  },
  radioLabel: {
    display: "flex",
    width: "100%",
    "& span": {
      color: grayColor[1],
      fontSize: ".92rem"
    },
  },
  radioText: {

  },
  radioPrice: {
    marginLeft: "auto"
  },
  radioLink: {
    marginLeft: "37px",
    fontSize: ".8rem",
    "& a": {
      color: infoColor[0],
      display: "block"
    }
  }
};

export default questionStyle;