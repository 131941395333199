import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/styles/components/questionStyle";

const useStyles = makeStyles(styles);

const PickQuestion = ({ title, options, value, onItemSelected, handleClick, label, helperText, multiple }) => {
	const classes = useStyles();

	return (
		<React.Fragment>
			<h4 className={classes.cardTitle}>{title}</h4>
			<div className={!multiple && classes.options || ""}>
				<TextField
			        label={label}
			        select
			        value={value}
			        onChange={e => onItemSelected(e.target.value)}
			        className={classes.textField}
			        helperText={helperText}
			    >
			    {options.map(option => (
		            <MenuItem key={option.value} value={option.value}>
		              {option.label}
		            </MenuItem>
		          ))}
		        </TextField>				
			</div>
			{handleClick && <Button 
				color="primary"
				fullWidth
				onClick={e => handleClick()}
			>
				Seguir
			</Button>}
		</React.Fragment>
	)
}

PickQuestion.propTypes = {
  handleClick: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  title: PropTypes.string,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  helperText: PropTypes.string.isRequired,  
  className: PropTypes.string,
  multiple: PropTypes.bool
}

export default PickQuestion;

/*import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from 'components/Autocomplete/Autocomplete';
import styles from "assets/jss/styles/components/questionStyle";

const useStyles = makeStyles(styles);

const PickQuestion = ({ title, onItemSelected, options, label, placeholder, hoverColor, clearState, className }) => {
	const classes = useStyles();

	const optionsClass = classNames({
		[classes.options]: true,
		[className]: className !== undefined
	});

	return (
		<React.Fragment>
			<h4 className={classes.cardTitle}>{title}</h4>
			<div className={optionsClass}>
			    <Autocomplete 
			    	options={options}
			    	label={label}
			    	placeholder={placeholder}
			    	hoverColor={hoverColor}
			    	onItemSelected={onItemSelected}
			    	clearState={clearState}
			    />				
			</div>
		</React.Fragment>
	)
}

PickQuestion.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  hoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ]),
  className: PropTypes.string
}

export default PickQuestion;*/