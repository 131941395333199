import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/styles/components/questionStyle";

const useStyles = makeStyles(styles);

const TimeQuestion = ({ title, numbers, periods, number, period, onNumberSelected, onPeriodSelected }) => {
	const classes = useStyles();

	return (
		<React.Fragment>
			<h4 className={ classes.cardTitle }>{ title }</h4>
			<div className={ classes.options }>
				<div className={ classes.subSection }>
					{ numbers.map( num => {
						return (
							<Button 
								key={ num } 
								round
								color={number === num ? "primary" : "transparent"}
								className={classes.roundButton}
								onClick={e => onNumberSelected(num)}
							>
								{ num === 10 ? `>${num}` : num }
							</Button>
						)
					})}		
				</div>
				<div>
					{ periods.map( per => {
						return (
							<Button 
								key={ per.payload }
								color={ period === per.payload ? "primary" : "transparent" }
								className={ classes.periodButton }
								onClick={ e => onPeriodSelected(per) }
							>
								{ per.label }
							</Button>
						)
					})}		
				</div>				
			</div>
		</React.Fragment>
	)
}

TimeQuestion.propTypes = {
  title: PropTypes.string.isRequired,
  numbers: PropTypes.array.isRequired,
  periods: PropTypes.array.isRequired,
  number: PropTypes.number,
  period: PropTypes.string,
  onNumberSelected: PropTypes.func.isRequired,
  onPeriodSelected: PropTypes.func.isRequired
}

export default TimeQuestion;