import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import styles from "assets/jss/styles/components/questionStyle";

const useStyles = makeStyles(styles);

export default function GamaQuestion({
	mediaPrice,
	mediaContent,
	altaPrice,
	altaContent,
	exclusivePrice,
	exclusiveContent
}) {
  const [selectedEnabled, setSelectedEnabled] = React.useState("media");
  const classes = useStyles();
  return (
    <div>
      <h4 className={ classes.cardTitle }>Elige la Gama</h4>
      <div
        className={
          classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
        }
      >
        <FormControlLabel
          control={
            <Radio
              checked={selectedEnabled === "media"}
              onChange={() => setSelectedEnabled("media")}
              value="media"
              aria-label="Media"
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.radio,
                root: classes.radioRoot
              }}
            />
          }
          classes={{
            label: classes.label
          }}
          label={
          	<div className={classes.radioLabel}>
          		<span className={classes.radioText}>Media</span>
          		<span className={classes.radioPrice}>{mediaPrice} €</span>          		
          	</div>
          }
        />
        {mediaContent ? <p className={classes.radioLink}>{mediaContent}</p> : null}
      </div>
      <div
        className={
          classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
        }
      >
        <FormControlLabel
          control={
            <Radio
              checked={selectedEnabled === "alta"}
              onChange={() => setSelectedEnabled("alta")}
              value="alta"
              aria-label="Alta"
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.radio,
                root: classes.radioRoot
              }}
            />
          }
          classes={{
            label: classes.label
          }}
          label={
          	<div className={classes.radioLabel}>
          		<span className={classes.radioText}>Alta</span>
          		<span className={classes.radioPrice}>{altaPrice} €</span>
          	</div>
          }
        />
        {altaContent ? <p className={classes.radioLink}>{altaContent}</p> : null}
      </div>
      <div
        className={
          classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
        }
      >
        <FormControlLabel
          control={
            <Radio
              checked={selectedEnabled === "exclusive"}
              onChange={() => setSelectedEnabled("exclusive")}
              value="exclusive"
              aria-label="Exclusive"
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.radio,
                root: classes.radioRoot
              }}
            />
          }
          classes={{
            label: classes.label
          }}
          label={
          	<div className={classes.radioLabel}>
          		<span className={classes.radioText}>Exclusive</span>
          		<span className={classes.radioPrice}>{exclusivePrice} €</span>
          	</div>
          }
        />
        {exclusiveContent ? <p className={classes.radioLink}>{exclusiveContent}</p> : null}
      </div>
    </div>
  );
}