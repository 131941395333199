import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/styles/components/questionStyle";

const useStyles = makeStyles(styles);

const ChooseQuestion = ({ title, options }) => {
	const classes = useStyles();

	return (
		<React.Fragment>
			<h4 className={classes.cardTitle}>{title}</h4>
			<div className={classes.options}>
				{options.map(opt => {
					return (
						<Button 
							key={opt.text} 
							className={classes.btnWrap}
							fullWidth
							color={opt.color || "primary"}
							onClick={e => opt.onClick()}
						>
							{opt.text}
						</Button>
					)
				})}						
			</div>
		</React.Fragment>
	)
}

ChooseQuestion.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
}

export default ChooseQuestion;