import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/styles/components/questionStyle";

const useStyles = makeStyles(styles);

const CommentQuestion = ({ title, comment, handleComment, handleClick }) => {
	const classes = useStyles();

	return (
		<React.Fragment>
			<h4 className={classes.cardTitle}>{title}</h4>
			<div className={classes.options}>
				<TextField
			        id="multiline-comment"
			        label="Cuéntanos tu experiencia"
			        multiline
			        rowsMax="8"
			        value={comment}
			        onChange={e => handleComment(e.target.value)}
			        className={classes.textField}
			    />						
			</div>
			<Button 
				color="primary"
				fullWidth
				onClick={e => handleClick()}
			>
				Seguir
			</Button>
		</React.Fragment>
	)
}

CommentQuestion.propTypes = {
  title: PropTypes.string.isRequired,
  handleComment: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  comment: PropTypes.string.isRequired
}

export default CommentQuestion;