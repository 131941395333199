import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ALink from 'components/ALink/ALink';
import Button from 'components/CustomButtons/Button';
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import modalStyle from "assets/jss/styles/components/modalStyle.js";
import { modalTransitionDuration } from "assets/jss/styles.js";

const useStyles = makeStyles(modalStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

const DialogTitle = (props => {
  const { classes, onClose, headerText, ...other } = props;
  return (
    <MuiDialogTitle 
      disableTypography 
      className={classNames({
        [classes.root]: true, 
        [classes.headerBg]: headerText !== undefined
      })} 
      {...other}
    >
      {headerText && <p className={classes.title}>{headerText}</p>}
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>          
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const CustomModal = ({ 
  isButton, 
  buttonText, 
  buttonProps, 
  icon, 
  Component, 
  linkClass, 
  headerText, 
  disableBackdropClick,
  paperClass,
  dialogProps,
  ...rest }) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = React.useState(false);

  return (
    <React.Fragment>
      {isButton ?
        <Button onClick={() => setOpenModal(true)} {...buttonProps}>
          {buttonText}
        </Button> :
        <ALink
          Icon={icon}
          color="info"
          iconColor="info"
          text={buttonText}
          className={linkClass}
          onClick={() => setOpenModal(true)}
        />
      }
    
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + paperClass
        }}
        open={openModal}
        TransitionComponent={Transition}
        transitionDuration={modalTransitionDuration}
        keepMounted
        disableBackdropClick={disableBackdropClick}
        onClose={() => setOpenModal(false)}
        aria-labelledby="login-modal-slide-title"
        aria-describedby="login-modal-slide-description"
        {...dialogProps}
      >
        <DialogTitle onClose={() => setOpenModal(false)} classes={classes} headerText={headerText} />
        {openModal && 
            <Component 
              handleExit={() => setOpenModal(false)} 
              {...rest}
            />
        }
      </Dialog>
    </React.Fragment>
)}

CustomModal.propTypes = {
  buttonText: PropTypes.string,
  buttonProps: PropTypes.object,
  isButton: PropTypes.bool,
  icon: PropTypes.object,
  Component: PropTypes.func,
  linkClass: PropTypes.string,
  headerText: PropTypes.string,
  disableBackdropClick: PropTypes.bool,
  paperClass: PropTypes.string,
  setMessage: PropTypes.func,
  dialogProps: PropTypes.object
}

export default CustomModal;