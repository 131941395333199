import React from 'react';
import PropTypes from 'prop-types';
import CustomModal from 'components/CustomModal/CustomModal';
import NewBudget from 'components/Budget/NewBudget';

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/styles/components/budgetPanelStyle";
const useStyles = makeStyles(styles);

const BudgetPanel = () => {
	const classes = useStyles();

	return (
		<div className={classes.card}>
			<h2 className={classes.cardTitle}>Calcula el precio de la reforma de tu piso en 2 minutos</h2>
			<div className={classes.body}>
				<h5 className={classes.cardSubtitle}>Responde a un cuestionario y hazte una idea aproximada del coste final de la reforma de tu piso</h5>
			</div>

			<React.Fragment>
				<CustomModal 
					buttonText="Calcular el precio de tu reforma"
					disableBackdropClick
					isButton
					buttonProps={{
						color: "info"
					}}
					Component={NewBudget}
					dialogProps={{
						scroll: "paper",
						fullWidth: true
					}}
				/>
			</React.Fragment> 
		</div>
	)
}

BudgetPanel.propTypes = {
	// userId: PropTypes.string,
}

export default BudgetPanel;