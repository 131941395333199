import { 
  cardTitle, 
  cardSubtitle,
  modalMdSize,
  card,
} from "assets/jss/styles.js";

const leftPanelStyle = {
  card: {
    ...card,
    padding: "25px",
    textAlign: "center"
  },
  cardTitle: {
    ...cardTitle    
  },
  cardSubtitle: {
    ...cardSubtitle,
    paddingBottom: "10px",
    textTransform: "none"
  },
  body: {
    paddingTop: "20px",
    paddingBottom: "20px"
  },
  link: {
    marginBottom: "15px"
  },
  paperMd: {
    ...modalMdSize
  }
};

export default leftPanelStyle;