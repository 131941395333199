import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';

import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';

import {
  infoColor,
  title,
  subtitle
} from "assets/jss/styles.js";

const useStyles = makeStyles({
  progress: {
    color: infoColor[0],
    width: "5rem !important",
    height: "5rem !important",
  },
  icon: {
    color: infoColor[0],
    width: "5rem !important",
    height: "5rem !important",
    display: "flex",
    margin: "auto"
  },
  wrapperDiv: {
    marginTop: "40px",
    overflow: "hidden",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
  title: {
    ...title,
    textAlign: "center"
  },
  subtitle: {
    ...subtitle,
    textAlign: "center"
  },
  button: {
    marginTop: "50px"
  },
  div: {
    height: "100%"
  }
});

const ThanksMessage = ({ title, subtitle, handleExit, tabActive }) => {
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const update = tabActive === 11;

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [update])

  return (
    <div className={classes.wrapperDiv}>
      {loading ? <CircularProgress className={classes.progress} /> :
        <div className={classes.div}>
          <ThumbUpAltIcon className={classes.icon} />
          <h4 className={classes.title}>{title}</h4>
          {subtitle && <h5 className={classes.subtitle}>{subtitle}</h5>}
          <Button 
            color="primary"
            className={classes.button}
            onClick={e => handleExit()}
          >
            Imprimir PDF
          </Button>
          <Button 
            color="default"
            style={{float: "right"}}
            className={classes.button}
            onClick={e => handleExit()}
          >
            Salir
          </Button>
        </div>
      }
    </div>
  );
}

ThanksMessage.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  handleExit: PropTypes.func.isRequired,
  tabActive: PropTypes.number
}

export default ThanksMessage;