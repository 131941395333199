import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/styles/components/aLinkStyle.js";

const useStyles = makeStyles(styles);

const ALink = React.forwardRef(({ Icon, color, iconColor, text, className, ...rest }, ref) => {
	const classes = useStyles();
	const aClasses = classNames({
	    [classes.main]: true,
	    [classes[color]]: true,
	    [className]: className !== undefined
	});

	return (
		<a className={aClasses} {...rest}>
			<React.Fragment>
				{ Icon ? 
					<Icon 
						className={classNames(classes[iconColor], classes.icon)} 
					/> : null 
				}
				<span className={classes.text}>
					{ text }
				</span> 
			</React.Fragment>
		</a>
	)
})

ALink.defaultProps = {
	color: "info"
}

ALink.propTypes = {
	href: PropTypes.string,
	Icon: PropTypes.object,
	text: PropTypes.string.isRequired,
	className: PropTypes.string,
	color: PropTypes.oneOf([
	    "primary",
	    "info",
	    "success",
	    "gray"
    ]),
    iconColor: PropTypes.oneOf([
	    "primary",
	    "info",
	    "success",
	    "gray"
    ])
}

export default ALink;