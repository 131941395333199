import { 
  grayColor,
  title
} from "assets/jss/styles.js";

const modalStyle = theme => ({
  root: {
    margin: 0,
    zIndex: 10,
    display: "flex",
    justifyContent: "flex-end",
    padding: "5px 24px"
  },
  headerBg: {
    backgroundColor: grayColor[2]
  },
  modal: {
  	// width: "400px",
    overflowY: "visible"
  },
  closeButton: {
    color: theme.palette.grey[500],
    alignSelf: "flex-end"
  },
  title: {
    ...title,
    marginTop: 0,
    marginBottom: 0,
    alignSelf: "flex-start",
    width: "100%",
    padding: "12px 0"
  }
});

export default modalStyle;