import {
  primaryColor,
  successColor,
  infoColor,
  grayColor
} from "assets/jss/styles.js";

const aLinkStyle = theme => ({
  main: {
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  icon: {
    marginRight: "15px"
  },
  text: {
    "&:hover": {
      textDecoration: "underline"
    }
  },
  primary: {
    color: primaryColor[0]
  },
  info: {
    color: infoColor[0]
  },
  success: {
    color: successColor[0]
  },
  gray: {
    color: grayColor[1]
  }
});

export default aLinkStyle;